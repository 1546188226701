<template>
  <v-row class="LayoutCenter" mb-5>
    <v-col cols="12" md="8" offset-md="2">
      <v-div float-center width-100-percent max-width-720 text-center>
        <slot></slot>
      </v-div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LayoutCenter",
};
</script>
