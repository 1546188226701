<template>
  <v-div
    relative
    class="horizontal-key-metrics"
    :height="pillBackground ? '740' : 'auto'"
    :background-subdued="!pillBackground"
    :class="pillBackground ? '' : 'pt-8'"
  >
    <v-div
      v-if="pillBackground"
      :radius-right="radiusRight"
      absolute
      display-inline-block
      background-primary
      height="370"
      width="75%"
      left="0"
      >&nbsp;</v-div
    >
    <v-div
      v-if="pillBackground"
      :radius-left="radiusLeft"
      absolute
      display-inline-block
      background-secondary
      height="370"
      width="75%"
      top="285"
      right="0"
      >&nbsp;</v-div
    >

    <LayoutGutters>
      <v-row
        :justify="pillBackground ? 'start' : 'center'"
        :class="pillBackground ? 'horizontal-key-metrics__row' : ''"
      >
        <div class="d-flex horizontal-key-metrics__container pt-4">
          <PromotionMetric
            v-for="(promotion, index) in promotions"
            :key="index"
            :image="promotion.image"
            :color="promotion.color"
            :title="promotion.title"
            :text="promotion.text"
            :has-more-data="index < promotionsCount - 1"
            :is-horizontal="true"
            :spacing="promotionsSpacing"
            :inverted="pillBackground"
          />
        </div>
      </v-row>
    </LayoutGutters>

    <div class="text-center py-8">
      <v-btn
        class="gotoSubmit"
        :class="pillBackground ? 'black--text' : 'white--text'"
        :color="pillBackground ? '' : 'black'"
        rounded
        data-test-id="SubmitContentButton"
        aria-label="submit content"
        @click="submitContent"
      >
        Submit Content
      </v-btn>
    </div>
    <LayoutGutters>
      <v-row :justify="pillBackground ? 'end' : 'center'">
        <div
          class="horizontal-key-metrics__container width-percent-66 height-256"
        >
          <h2
            class="title font-weight-bold py-2 text-center"
            :class="pillBackground ? 'inverted--text' : ''"
          >
            Key Metrics
          </h2>
          <dl class="d-flex justify-space-around">
            <HeadlineMetric
              v-for="(metric, index) in metrics"
              :key="index"
              :title="metric.description"
              :value="metric.value"
              :has-more-data="index < metricsCount - 1"
              :is-horizontal="true"
              :spacing="metricsSpacing"
              :inverted="pillBackground"
              :is-dl="true"
            />
          </dl>
        </div>
      </v-row>
    </LayoutGutters>
  </v-div>
</template>

<script>
import { mapState } from "vuex";

import LayoutGutters from "~/components/layout/LayoutGutters";
import PromotionMetric from "~/components/metrics/components/PromotionMetric.vue";
import HeadlineMetric from "~/components/metrics/components/HeadlineMetric.vue";
import isUnauthorized from "~/plugins/utilities/isUnauthorized";

export default {
  name: "HorizontalKeyMetrics",
  components: {
    PromotionMetric,
    HeadlineMetric,
    LayoutGutters,
  },
  props: {
    metrics: { type: Array, required: true },
    promotions: { type: Array, required: true },
  },
  data() {
    return {};
  },
  computed: {
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    radiusRight() {
      return this.layout.horizontalKeyMetricsRadiusRight;
    },
    radiusLeft() {
      return this.layout.horizontalKeyMetricsRadiusLeft;
    },
    pillBackground() {
      return this.layout.horizontalKeyMetricsPillBackground;
    },
    promotionsCount() {
      return this.promotions.length;
    },
    metricsCount() {
      return this.metrics.length;
    },
    promotionsSpacing() {
      return 100 / this.promotions.length + "%";
    },
    metricsSpacing() {
      return 100 / this.metrics.length + "%";
    },
  },

  methods: {
    submitContent({ currentTarget }) {
      const redirectTo = `/engage/${this.partner}/submit-process/step-one`;

      if (isUnauthorized(this.$store.state.user, "USER")) {
        this.$root.$emit(
          "show-login-options-redirect",
          redirectTo,
          currentTarget
        );
      } else {
        this.$router.push({
          path: redirectTo,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.horizontal-key-metrics {
  &__row {
    max-width: 75%;
  }

  &__container {
    z-index: 1;
  }
}
</style>
