<template>
  <v-col
    class="BenefitsTile py-5"
    cols="12"
    md="4"
    align="center"
    :style="{ color: tileColor }"
  >
    <img class="BenefitsTileImg" :src="url" alt="" />
    <h2
      :style="{ color: tileColor }"
      :class="[
        displayIsSmallDesktop && 'BenefitsTileTitle-small-desktop',
        'pt-9',
        displayIsDesktop ? 'pb-7' : 'pb-5',
      ]"
    >
      Benefits for {{ title }}
    </h2>
    <v-div>
      <v-btn
        outlined
        rounded
        :to="`/engage/${partner}/about-information?show=cambridge-open-engage`"
        :color="tileColor"
        :data-test-id="generateDataTestId()"
      >
        View
        <span class="d-sr-only"
          >benefits for researchers, authors and partners</span
        >
      </v-btn>
    </v-div>
  </v-col>
</template>

<script>
import { displayIsDesktop } from "~/components/utilities/displayBreakpoints";

export default {
  name: "BenefitsTile",
  props: {
    url: { type: String, required: true },
    title: { type: String, required: true },
    tileColor: { type: String, default: "primary" },
    dataTestId: { type: [String, null], default: null },
  },
  computed: {
    displayIsDesktop,
    displayIsSmallDesktop() {
      return this.$vuetify.breakpoint.md;
    },
    partner() {
      return this.$config.partner;
    },
  },
  methods: {
    generateDataTestId() {
      const upperCasedTitle =
        this.title.charAt(0).toUpperCase() + this.title.slice(1);
      return `Benefits-${upperCasedTitle}Button`;
    },
  },
};
</script>

<style scoped>
.BenefitsTileImg {
  max-width: 160px;
}

.BenefitsTileTitle-small-desktop {
  max-width: 130px;
}
</style>
