<template>
  <v-div class="PublicBanner" width-100-percent overflow-hidden>
    <LargeHero v-if="displayIsTablet || displayIsDesktop" />
    <SmallHero v-else />
  </v-div>
</template>

<script>
import LargeHero from "~/components/banner/components/LargeHero.vue";
import SmallHero from "~/components/banner/components/SmallHero.vue";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "PublicBanner",
  components: {
    LargeHero,
    SmallHero,
  },
  data() {
    return {};
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
  },
};
</script>
