<template>
  <svg class="width-24 height-24 muted--text" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
  </svg>
</template>

<script>
export default {
  name: "OpenIndicatorIcon",
};
</script>
