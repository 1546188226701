<template>
  <v-row class="Benefits" :class="displayIsDesktop ? 'py-16' : 'py-7'">
    <BenefitsTile
      v-for="([title, url], index) in Object.entries(images)"
      :key="index"
      :url="url"
      :title="title"
      :tile-color="colors.inverted"
    />
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import { displayIsDesktop } from "~/components/utilities/displayBreakpoints";

import BenefitsTile from "~/components/public-dashboard/BenefitsTile.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Benefits",
  components: {
    BenefitsTile,
  },
  computed: {
    ...mapState({
      images: (state) => state.theme.images.publicDashboard.benefits,
      colors: (state) => state.theme.colors,
    }),
    displayIsDesktop,
  },
};
</script>
