<template>
  <ContainerPage class="PublicDashboardPartner">
    <PublicBanner />
    <LayoutGutters>
      <v-div
        :pl-5="displayIsMobile"
        :pr-5="displayIsMobile"
        :pl-3="displayIsTablet"
        :pr-3="displayIsTablet"
      >
        <MarketingMessages />
      </v-div>
    </LayoutGutters>
    <LayoutGutters v-if="showSponsors" :no-padding="true" :col="12" :offset="0">
      <v-div
        :pl-5="displayIsMobile"
        :pr-5="displayIsMobile"
        :pl-3="displayIsTablet"
        :pr-3="displayIsTablet"
      >
        <PartnerSponsors />
      </v-div>
    </LayoutGutters>
    <LayoutGutters>
      <SummarizeGroups :groups="categorySummaries" />
    </LayoutGutters>
    <LayoutGutters>
      <SelectedArticles
        title="Latest Content"
        :articles="latestItems"
        :show-content-type="showContentTypeHome"
        data-test-id="PreprintCard"
      />
      <v-div text-center>
        <v-btn
          class="toLatestListing"
          outlined
          rounded
          color="primary"
          data-test-id="ViewAllContentButton"
          :to="`/engage/${partner}/latest-listing`"
        >
          View All
          <span class="d-sr-only">&nbsp;Latest Content</span>
        </v-btn>
      </v-div>
    </LayoutGutters>
    <KeyMetrics :metrics="metrics" />

    <LayoutCenter>
      <h2 v-if="publicDashboardShowEvents" class="title font-weight-bold mt-4">
        Latest Events
      </h2>
    </LayoutCenter>
    <LayoutGutters>
      <LatestEvents
        v-if="publicDashboardShowEvents"
        data-test-id-event-summary="EventCard"
        data-test-id-view-all-event="ViewAllEventsButton"
        :events="latestEvents"
      />
    </LayoutGutters>
  </ContainerPage>
</template>

<script>
import { mapState } from "vuex";
import mapFlags from "~/plugins/utilities/mapFlags";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

import ContainerPage from "~/components/layout/ContainerPage";
import LayoutCenter from "~/components/layout/LayoutCenter";
import LayoutGutters from "~/components/layout/LayoutGutters";

import PublicBanner from "~/components/banner/PublicBanner.vue";
import SummarizeGroups from "~/components/groups/SummarizeGroups.vue";
import SelectedArticles from "~/components/articles/SelectedArticles.vue";
import KeyMetrics from "~/components/metrics/KeyMetrics.vue";

import LatestEvents from "~/components/events/LatestEvents.vue";
import MarketingMessages from "~/components/page/components/MarketingMessages.vue";
import PartnerSponsors from "~/components/page/components/PartnerSponsors.vue";

export default {
  name: "PublicDashboardPartner",
  components: {
    ContainerPage,
    LayoutGutters,
    LayoutCenter,
    PublicBanner,
    SummarizeGroups,
    SelectedArticles,
    KeyMetrics,
    LatestEvents,
    MarketingMessages,
    PartnerSponsors,
  },
  props: {
    displayIs: { type: String, required: true },
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      theme: (state) => state.theme,
      marketing: (state) => state.theme.marketing,
      layout: (state) => state.theme.layout,
      categorySummaries: (state) => state.currentOrigin.categorySummaries,
      metrics: (state) => state.currentOrigin.metrics,
      latestEvents: (state) => state.currentOrigin.latestEvents,
      latestItems: (state) => state.currentOrigin.latestItems,
      showSponsors: (state) => state.theme.sponsorsList?.length > 0,
    }),
    ...mapFlags(["showContentTypeHome", "publicDashboardShowEvents"]),
    showMarketingMessage() {
      return this.marketing && this.marketing.show === true;
    },
  },
};
</script>

<style>
.PublicDashboardPartner .toLatestListing h3 {
  font-size: 0.875rem;
}
</style>
