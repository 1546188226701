<template>
  <div class="clear-field-button">
    <span
      v-if="announcedFieldCleared"
      class="d-sr-only"
      role="status"
      aria-atomic="true"
      >{{ clearStatusMsg }}</span
    >
    <v-btn
      :key="disabled"
      ref="button"
      elevation="0"
      :disabled="disabled"
      color="action"
      class="mb-2 rounded width-24 height-24 has-action px-0"
      :class="marginLeft"
      @click="clearField"
    >
      <v-icon :dark="true" aria-hidden="true" class="white--text">{{
        mdiClose
      }}</v-icon>
      <span class="d-sr-only">{{ ariaLabel }}</span>
    </v-btn>
  </div>
</template>

<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "ClearFieldButton",
  props: {
    disabled: { type: Boolean, default: false },
    fieldName: { type: String, default: "" },
    marginLeft: { type: String, default: "ml-3" },
    announcedFieldCleared: { type: Boolean, default: true },
  },
  data() {
    return {
      clearStatusMsg: "",
      // Icons
      mdiClose,
    };
  },
  computed: {
    ariaLabel() {
      return `clear ${this.fieldName.toLowerCase()}`;
    },
  },
  methods: {
    clearField() {
      this.clearStatusMsg = `${this.fieldName.toLowerCase()} cleared`;
      this.$emit("clear");
    },
    focus() {
      this.$refs.button?.$el.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-field-button::v-deep {
  .v-btn {
    min-width: 24px;
  }
}
</style>
