<template>
  <div class="LayoutBackground" :style="displayStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "LayoutBackground",
  props: {
    noGutters: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    align: { type: String, default: undefined },
    image: { type: String, default: undefined },
    secondImage: { type: String, default: undefined },
    thirdImage: { type: String, default: undefined },
  },
  computed: {
    displayStyle() {
      const style = {};
      style.backgroundImage = "";
      style.backgroundRepeat = "";
      style.backgroundSize = "";
      style.backgroundPosition = "";

      if (this.thirdImage) {
        style.backgroundImage =
          style.backgroundImage + `,url(${this.thirdImage})`;
        style.backgroundRepeat = style.backgroundRepeat + ",repeat-x";
        style.backgroundSize = style.backgroundSize + ",100%";
        style.backgroundPosition = style.backgroundPosition + ",bottom center";
      }

      if (this.secondImage) {
        style.backgroundImage =
          style.backgroundImage + `,url(${this.secondImage})`;
        style.backgroundRepeat = style.backgroundRepeat + ",repeat-x";
        style.backgroundSize = style.backgroundSize + ",100% 100%";
        style.backgroundPosition = style.backgroundPosition + ",0px 116px";
      }

      if (this.image) {
        style.backgroundImage = style.backgroundImage + `,url(${this.image})`;
        style.backgroundRepeat = style.backgroundRepeat + ",no-repeat";
        style.backgroundSize = style.backgroundSize + ",100%";
        style.backgroundPosition = style.backgroundPosition + ",0px 0px";
      }

      style.backgroundImage = style.backgroundImage.substring(1);
      style.backgroundRepeat = style.backgroundRepeat.substring(1);
      style.backgroundSize = style.backgroundSize.substring(1);
      style.backgroundPosition = style.backgroundPosition.substring(1);

      return style;
    },
  },
};
</script>
