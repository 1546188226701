<template>
  <v-div
    class="MarketingBanner"
    :class="displayIsDesktop && 'MarketingBanner-desktop'"
    relative
    width-100-percent
    :pt-5="displayIsTablet"
    px-5
    :style="[
      { backgroundImage: `url('${images.banner}')` },
      { paddingBottom: displayIsDesktop ? '200px' : '0' },
    ]"
  >
    <v-div
      class="MarketingBanner-container"
      :class="`MarketingBanner-container-${displayIs.toLowerCase()}`"
      float-center
      relative
      layer-10
      ml-5
      mr-5
    >
      <v-div pt-5>
        <MarketingTitle />
      </v-div>
      <v-div pt-5>
        <MarketingSearch :choose-subject="preselectSubject" />
      </v-div>
    </v-div>
    <v-div
      width-100-percent
      height-100-percent
      top="0"
      right="0"
      absolute
      layer-2
    >
      <div
        class="background-image"
        :class="`background-image-${$vuetify.breakpoint.name}`"
        :style="{ backgroundImage: `url('${images.bannerImage}')` }"
      />
    </v-div>
  </v-div>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
  displayIs,
} from "~/components/utilities/displayBreakpoints";

import MarketingTitle from "~/components/public-dashboard/MarketingTitle.vue";
import MarketingSearch from "~/components/public-dashboard/MarketingSearch.vue";

export default {
  name: "MarketingBanner",
  components: {
    MarketingTitle,
    MarketingSearch,
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      images: (state) => state.theme.images.publicDashboard,
      layout: (state) => state.theme.layout,
      metrics: (state) => state.currentOrigin.metrics,
      latestItems: (state) => state.currentOrigin.latestItems,
    }),
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    displayIs,
    preselectSubject() {
      return this.layout.submissionPreselectSubject;
    },
  },
};
</script>

<style scoped>
.MarketingBanner {
  z-index: 0;
  background-repeat: repeat-x;
  background-size: 100%;
  background-position: bottom center;
}

.MarketingBanner-container {
  max-width: 472px;
  min-height: 620px;
  padding-top: 70px;
}

.MarketingBanner-container-tablet {
  min-height: 720px;
}

.MarketingBanner-container-mobile {
  min-height: 720px;
  padding-top: 10px;
}

.MarketingBanner-desktop {
  padding-left: 15%;
  padding-right: 15%;
}

.background-image {
  height: 100%;
  width: 100%;
}

.background-image-xl {
  background-size: auto 1993px;
  background-position-x: calc(100vw - 1055px);
  background-position-y: -635px;
}
.background-image-lg {
  background-size: auto 1581px;
  background-position-x: calc(100vw - 837px);
  background-position-y: -504px;
}
.background-image-md {
  background-size: auto 1214px;
  background-position-x: calc(100vw - 603px);
  background-position-y: -273px;
}
.background-image-sm {
  background-size: auto 1093px;
  background-position-x: calc(100vw - 500px);
  background-position-y: -175px;
}
.background-image-xs {
  background-size: auto 840px;
  background-position-x: calc(100vw - 344px);
  background-position-y: -95px;
}
</style>
