<template>
  <PublicDashboardInstitution v-if="isInstitution" />
  <PublicDashboardPlatform
    v-else-if="showPlatformDashboard"
    :display-is="displayIs"
  />
  <PublicDashboardPartner v-else :display-is="displayIs" />
</template>

<script>
import { mapState } from "vuex";
import buildActionDispatcher from "~/actions/utilities/buildActionDispatcher";

import PublicDashboardPartner from "~/components/page/PublicDashboardPartner.vue";
import PublicDashboardInstitution from "~/components/page/PublicDashboardInstitution.vue";
import PublicDashboardPlatform from "~/components/page/PublicDashboardPlatform.vue";

import { displayIs } from "~/components/utilities/displayBreakpoints";
import toCanonicalUrl from "~/plugins/utilities/toCanonicalUrl.js";
import getPageTitle from "~/plugins/utilities/getPageTitle.js";
import getSiteName from "~/plugins/utilities/getSiteName.js";

export default {
  name: "PublicDashboard",
  components: {
    PublicDashboardInstitution,
    PublicDashboardPartner,
    PublicDashboardPlatform,
  },
  async fetch(context) {
    const vuexAction =
      context.$config.partner === "coe"
        ? "loadPlatformDashboard"
        : "loadPublicDashboard";
    await buildActionDispatcher(vuexAction)(context);
  },
  head() {
    const { siteName, domain, structuredData, partner } = this;

    const pageTitle = `Homepage | ${getPageTitle(siteName)}`;

    const script = [];

    if (structuredData) {
      script.push({
        innerHTML: JSON.stringify(structuredData),
        type: "application/ld+json",
      });
    }

    const canonicalUrl = toCanonicalUrl("public-dashboard", partner);

    return {
      __dangerouslyDisableSanitizers: ["script"],
      script,

      title: pageTitle,

      meta: [
        {
          hid: "description",
          name: "description",
          content: `${siteName} is a free-to-access pre-publication platform dedicated to early research outputs in ${domain}.`,
        },
      ],
      link: [{ rel: "canonical", href: canonicalUrl }],
    };
  },
  computed: {
    ...mapState({
      structuredData: (state) => state.theme.structuredData,
      siteName: (state) => getSiteName(state.theme),
      domain: (state) => state.theme.domain,
      theme: (state) => state.theme,
      isInstitution: (state) => state.theme.isInstitution,
    }),
    displayIs,
    partner() {
      return this.$config.partner;
    },
    showPlatformDashboard() {
      return this.partner === "coe";
    },
  },
};
</script>
