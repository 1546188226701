<template>
  <v-col
    class="HeadlineMetric primary--text px-0 pt-0 pb-4 my-2"
    :class="{ verticalBorder }"
    :cols="cols"
    justify="center"
    :style="{ borderColor: theme.colors.primary }"
  >
    <dd class="px-4">
      {{ value ? value.toLocaleString() : "0" }}{{ unit }}
      <v-div :class="{ horizontalBorder }" background-accent />
    </dd>
    <dl>
      <dt>{{ title }}</dt>
    </dl>
  </v-col>
</template>

<script>
import { mapState } from "vuex";
import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
  displayIs,
} from "~/components/utilities/displayBreakpoints";
export default {
  props: {
    title: { type: String, required: true },
    value: { type: Number, required: true },
    unit: { type: String, default: "" },
    hasMoreData: { type: Boolean, default: false },
  },
  data() {
    return {
      DESKTOP_COLS: 12,
      TABLET_COLS: 6,
      MOBILE_COLS: 3,
    };
  },
  computed: {
    displayIs,
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    ...mapState({
      theme: (state) => state.theme,
    }),
    verticalBorder() {
      return !this.displayIsMobile && this.hasMoreData;
    },
    horizontalBorder() {
      return this.displayIsMobile && this.hasMoreData;
    },
    cols() {
      return this.displayIsMobile
        ? this.DESKTOP_COLS
        : this.displayIsTablet
        ? this.TABLET_COLS
        : this.MOBILE_COLS;
    },
  },
};
</script>

<style scoped>
.HeadlineMetric {
  position: relative;
  min-width: 300px;
}

.verticalBorder {
  border-right: 1px solid;
}

.horizontalBorder {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  width: 92px;
  height: 1px;
}

dd {
  font-size: 52px;
}

dt {
  margin-top: -10px;
}
</style>
