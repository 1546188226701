<template>
  <div class="small-hero">
    <div class="position-relative height-64">
      <div
        v-if="!images.heroBanner"
        class="small-hero__rounded-banner secondary d-block left-0"
        :style="{ borderRadius: `0 ${borderRadius}px ${borderRadius}px 0` }"
      />
      <div
        v-if="!images.heroBanner"
        class="small-hero__rounded-banner primary d-block right-0"
        :style="{ borderRadius: `${borderRadius}px 0 0 ${borderRadius}px` }"
      />

      <div
        v-if="images.heroBanner"
        class="inverted--text text-center title position-absolute width-percent-100 top-16"
      >
        <div
          class="small-hero__banner-placeholder"
          :style="{ backgroundImage: `url('${images.heroBanner}')` }"
        ></div>
        <div
          class="overlay position-absolute height-percent-100 width-percent-100 top-0"
        />

        <h1
          class="text-center position-absolute title width-percent-100 top-16"
        >
          <strong>{{ theme.title }}</strong>
          <span class="small-hero__subtitle">{{ theme.subTitle }}</span>
          <span class="d-sr-only">&nbsp;Homepage</span>
        </h1>
      </div>

      <h1
        v-else
        class="text-center inverted--text title position-absolute width-percent-100 top-16 mx-2"
      >
        <strong>{{ theme.title }}</strong>
        <span class="small-hero__subtitle">{{ theme.subTitle }}</span>
        <span class="d-sr-only">&nbsp;Homepage</span>
      </h1>
    </div>
    <div class="inverted--text px-5">
      <SearchInput
        :partner="partner"
        data-test-search-bar-id="SearchBarText"
        data-test-search-icon-id="SearchButton"
        full-width
      />
      <v-btn
        class="gotoSubmit"
        rounded
        block
        color="primary"
        data-test-id="SubmitContentButton"
        :to="`/engage/${partner}/submit-process/step-one`"
        >Submit Content</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SearchInput from "~/components/search/SearchInput.vue";

import formatSearchQueryForText from "~/components/utilities/formatSearchQueryForText";
import extractSearchValue from "~/components/utilities/extractSearchValue";

export default {
  name: "SmallHero",
  components: {
    SearchInput,
  },
  computed: {
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      theme: (state) => state.theme,
      layout: (state) => state.theme.layout,
      images: (state) => state.theme.images,
    }),
    borderRadius() {
      return this.layout.smallHeroBorderRadius;
    },
  },
  methods: {
    onSearch(event) {
      const value = extractSearchValue(event);

      if (!value) {
        return;
      }

      const searchQuery = formatSearchQueryForText(value);

      this.$router.push({
        path: `/engage/${this.partner}/search-dashboard?${searchQuery}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-hero {
  &__rounded-banner {
    height: 100%;
    width: calc(50% + 16px);
    position: absolute;
  }
  &__subtitle {
    font-weight: lighter;
  }
  &__banner-placeholder {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 64px;
  }
}
</style>
