<template>
  <div class="marketing-title">
    <div class="text-center mb-4">
      <img class="marketing-title__logo" :src="theme.images.whiteLogo" alt="" />
      <h1 class="d-sr-only">Cambridge Open Engage Homepage</h1>
    </div>
    <div class="inverted--text headline text-center mb-4">
      The collaborative site to upload, share and advance your research
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MarketingTitle",
  data() {
    return {
      displayLabel: "Cambridge Open Engage",
      inverted: true,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
    }),
  },
};
</script>

<style lang="scss" scoped>
.marketing-title {
  &__logo {
    width: 100%;
    max-width: 300px;
  }
}

@media only screen and (min-width: 600px) {
  .marketing-title {
    &__logo {
      max-width: 360px;
    }
  }
}
</style>
