<template>
  <v-div
    class="PromotionMetric px-3"
    :class="inverted ? '' : 'py-8'"
    :border-inverted-right-thin="
      inverted === true && hasMoreData !== false && isHorizontal === true
    "
    :border-softened-right-thin="
      inverted === false && hasMoreData !== false && isHorizontal === true
    "
    :border-inverted-bottom-this="
      hasMoreData !== false && isHorizontal === false
    "
    :d-inline-block="isHorizontal === true"
    :width="spacing"
    float-center
    text-center
    align-top
  >
    <v-icon size="96" :color="color">{{ getImageIcon }}</v-icon>
    <h2 class="display-1" :class="inverted ? 'inverted--text' : ''">
      {{ title }}
    </h2>
    <v-div body :foreground-inverted="inverted" font-smaller pa-2>{{
      text
    }}</v-div>
  </v-div>
</template>

<script>
import { mdiClipboardText, mdiMessageAlert, mdiAccountMultiple } from "@mdi/js";
export default {
  name: "PromotionMetric",
  props: {
    image: { type: String, required: true },
    color: { type: String, required: true },
    title: { type: String, required: true },
    text: { type: String, required: true },
    hasMoreData: { type: Boolean, default: false },
    isHorizontal: { type: Boolean, default: true },
    inverted: { type: Boolean, default: true },
    spacing: { type: [Number, String], default: "100%" },
  },
  computed: {
    getImageIcon() {
      switch (this.image) {
        case "assignment":
          return mdiClipboardText;
        case "announcement":
          return mdiMessageAlert;
        case "group":
          return mdiAccountMultiple;
        default:
          return this.image;
      }
    },
  },
};
</script>
