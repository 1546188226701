<template>
  <v-div class="LargeHero d-flex align-end" height="320" relative>
    <v-div
      v-if="!images.heroBanner"
      background-secondary
      height-100-percent
      width="50% + 55px"
      d-block
      absolute
      left-0
      :radius-right="borderRadiusLeft"
    />
    <v-div
      v-if="!images.heroBanner"
      background-primary
      height-100-percent
      width="50% + 55px"
      d-block
      absolute
      right-0
      :radius-left="borderRadiusRight"
    />
    <v-div
      v-else
      absolute
      height-100-percent
      width-100-percent
      :style="{ backgroundImage: `url('${images.heroBanner}')` }"
      class="banner-placeholder"
    />

    <v-div
      text-center
      :foreground-primary="!invertedTitle"
      :foreground-inverted="invertedTitle"
      absolute
      width-100-percent
      :top="verticalOffset"
    >
      <v-div
        v-if="showPublicDashboardLogo"
        elevation-4
        d-inline-block
        px-3
        :class="{ whiteBackground: showPublicDashboardLogoBackground }"
        width="280px"
        height="130px"
      >
        <img :src="logo" alt="" class="heroLogo" />
      </v-div>
      <h1 :class="[{ 'd-sr-only': showPublicDashboardLogo }, 'display-3']">
        <strong>{{ theme.title }}</strong>
        <span class="subTitle">{{ theme.subTitle }}</span>
        <span class="d-sr-only">&nbsp;Homepage</span>
      </h1>
    </v-div>

    <v-div
      v-if="showLargeHeroOverlay"
      width-100-percent
      height-100-percent
      absolute
      top="240"
      class="overlay"
    />

    <LayoutGutters :no-padding="true">
      <div class="d-flex justify-space-between align-center">
        <div class="width-percent-33" :class="displayIsTablet && 'pl-2'">
          <SearchInput
            :partner="partner"
            :inverted="invertedTitle"
            data-test-search-bar-id="SearchBarText"
            data-test-search-icon-id="SearchButton"
          />
        </div>

        <div
          class="width-percent-33"
          :class="displayIsTablet ? 'pr-2' : '--nudge-right'"
        >
          <v-btn
            class="gotoSubmit"
            rounded
            block
            color="button-headline"
            data-test-id="SubmitContentButton"
            @click="submitContent"
            >Submit Content</v-btn
          >
        </div>
      </div>
    </LayoutGutters>
  </v-div>
</template>

<script>
import { mapState } from "vuex";

import SearchInput from "~/components/search/SearchInput.vue";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";
import isUnauthorized from "~/plugins/utilities/isUnauthorized";
import mapFlags from "~/plugins/utilities/mapFlags";
import LayoutGutters from "~/components/layout/LayoutGutters.vue";

export default {
  name: "LargeHero",
  components: {
    SearchInput,
    LayoutGutters,
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    ...mapFlags([
      "showLargeHeroOverlay",
      "showPublicDashboardLogo",
      "showPublicDashboardLogoBackground",
    ]),
    ...mapState({
      theme: (state) => state.theme,
      layout: (state) => state.theme.layout,
      images: (state) => state.theme.images,
      logo: (state) => state.theme.images.coloredLogo,
    }),
    invertedTitle() {
      return this.layout.largeHeroInvertedTitle;
    },
    verticalOffset() {
      return this.showPublicDashboardLogo
        ? "50px"
        : this.layout.largeHeroTitleVerticalOffset;
    },
    borderRadiusLeft() {
      return this.layout.largeHeroBorderRadiusLeft;
    },
    borderRadiusRight() {
      return this.layout.largeHeroBorderRadiusRight;
    },
  },
  methods: {
    submitContent({ currentTarget }) {
      const redirectTo = `/engage/${this.partner}/submit-process/step-one`;

      if (isUnauthorized(this.$store.state.user, "USER")) {
        this.$root.$emit(
          "show-login-options-redirect",
          redirectTo,
          currentTarget
        );
      } else {
        this.$router.push({
          path: redirectTo,
        });
      }
    },
  },
};
</script>

<style>
.LargeHero {
  overflow: hidden !important;
}
.LargeHero .banner-placeholder {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.LargeHero .v-div.--nudge-right {
  margin-right: -4px;
}

.LargeHero .subTitle {
  font-weight: lighter;
}

.LargeHero .whiteBackground {
  background: white;
}

.LargeHero img.heroLogo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
