<template>
  <div class="vertical-key-metrics">
    <div
      class="text-center pa-4"
      :class="pillBackground ? 'primary' : 'subdued'"
    >
      <PromotionMetric
        v-for="(promotion, index) in promotions"
        :key="index"
        :image="promotion.image"
        :color="promotion.color"
        :title="promotion.title"
        :text="promotion.text"
        :has-more-data="false"
        :is-horizontal="false"
        :inverted="pillBackground"
        :spacing="256"
      />
    </div>
    <div
      class="height-64 text-center pt-2"
      :class="pillBackground ? 'secondary' : 'subdued'"
    >
      <v-btn
        class="gotoSubmit"
        :class="pillBackground ? 'black--text' : 'white--text'"
        :color="pillBackground ? '' : 'black'"
        rounded
        data-test-id="SubmitContentButton"
        @click="submitContent"
        >Submit Content</v-btn
      >
    </div>
    <div
      class="text-center px-4"
      :class="pillBackground ? 'secondary' : 'subdued'"
    >
      <div
        class="title font-weight-bold pt-4"
        :class="pillBackground && 'inverted--text'"
      >
        Key Metrics
      </div>
      <dl>
        <HeadlineMetric
          v-for="(metric, index) in metrics"
          :key="index"
          :title="metric.description"
          :value="metric.value"
          :has-more-data="false"
          :is-horizontal="false"
          :inverted="pillBackground"
          :is-dl="true"
        />
      </dl>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import PromotionMetric from "~/components/metrics/components/PromotionMetric.vue";
import HeadlineMetric from "~/components/metrics/components/HeadlineMetric.vue";
import isUnauthorized from "~/plugins/utilities/isUnauthorized";

export default {
  name: "VerticalKeyMetrics",
  components: {
    PromotionMetric,
    HeadlineMetric,
  },
  props: {
    metrics: { type: Array, required: true },
    promotions: { type: Array, required: true },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    partner() {
      return this.$config.partner;
    },
    promotionsCount() {
      return this.promotions.length;
    },
    metricsCount() {
      return this.metrics.length;
    },
    pillBackground() {
      return this.layout.horizontalKeyMetricsPillBackground;
    },
  },
  methods: {
    submitContent() {
      const redirectTo = `/engage/${this.partner}/submit-process/step-one`;

      if (isUnauthorized(this.$store.state.user, "USER")) {
        this.$root.$emit("show-login-options-redirect", redirectTo);
      } else {
        this.$router.push({
          path: redirectTo,
        });
      }
    },
  },
};
</script>
