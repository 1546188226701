<template>
  <ContainerPage class="public-dashboard-institution">
    <LayoutFill no-gutters>
      <LargeHeroInstitution
        :banner="images.heroBanner"
        :description="description"
        :logo="images.coloredLogo"
        :label="label"
      />
    </LayoutFill>
    <PageContent
      :metrics="metrics"
      :latest-items="latestItems"
      :latest-events="latestEvents"
    />
  </ContainerPage>
</template>

<script>
import { mapState } from "vuex";
import ContainerPage from "~/components/layout/ContainerPage";

import LayoutFill from "~/components/layout/LayoutFill";
import LargeHeroInstitution from "~/components/page/institution/LargeHeroInstitution.vue";
import PageContent from "~/components/page/institution/PageContentInstitution.vue";

export default {
  name: "PublicDashboardInstitution",
  components: {
    ContainerPage,
    LayoutFill,
    PageContent,
    LargeHeroInstitution,
  },
  data() {
    return {
      description:
        "Research & explore content, submit your article, and understand where you fit in at any stage of your institution journey.",
      label: "Search for all content",
    };
  },
  computed: {
    ...mapState({
      images: (state) => state.theme.images,
      metrics: (state) => state.currentOrigin.metrics,
      latestItems: (state) => state.currentOrigin.latestItems,
      latestEvents: (state) => state.currentOrigin.latestEvents,
    }),
  },
};
</script>
