<template>
  <div class="marketingMessages">
    <div v-if="showMarketingMessages && unleashMessages.length">
      <AlertMessageContainer
        v-for="(uMessage, index) in unleashMessages"
        :key="index"
        :show-alert="uMessage.show"
        :type="uMessage.type"
        :aria-label="uMessage.ariaLabel || 'Marketing information'"
        :icon="uMessage.icon"
        :title="uMessage.title"
        :message="uMessage.message"
      />
    </div>
    <div v-for="message in marketing" :key="message.title">
      <AlertMessageContainer
        :show-alert="message.show"
        :type="message.type"
        :aria-label="message.ariaLabel || 'Marketing information'"
        :icon="message.icon"
        :title="message.title"
        :message="message.message"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import mapFlags from "~/plugins/utilities/mapFlags";

import AlertMessageContainer from "~/components/page/components/AlertMessageContainer.vue";

export default {
  name: "MarketingMessages",
  components: { AlertMessageContainer },
  computed: {
    partner() {
      return this.$config.partner;
    },
    ...mapFlags([
      "COE.ShowMarketingMessages",
      "MIIR.ShowMarketingMessages",
      "APSA.ShowMarketingMessages",
      "CHEMRXIV.ShowMarketingMessages",
    ]),
    ...mapState({
      marketing: (state) => state.theme.marketing,
    }),
    showMarketingMessages() {
      return this[`${this.partner.toUpperCase()}.ShowMarketingMessages`];
    },
    unleashMessages() {
      if (this.showMarketingMessages) {
        const { name, payload } = this.$unleash.getVariant(
          `${this.partner.toUpperCase()}.ShowMarketingMessages`
        );

        if (name === "messages" && payload.type === "json") {
          const parsedMessages = JSON.parse(payload.value);

          if (Array.isArray(parsedMessages)) {
            return parsedMessages;
          }

          return [parsedMessages];
        }
      }

      return [];
    },
  },
};
</script>

<style>
.marketingMessages h2 {
  font-size: 1.17em;
}
</style>
