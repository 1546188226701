var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ContainerGrid',{staticClass:"ContentSelectWrapper",attrs:{"no-padding":_vm.noPadding}},[_c('v-row',{staticClass:"px-3 px-md-0"},_vm._l((_vm.dataSelect),function({
        defaultOption,
        label,
        options,
        type,
        initialValue,
        dataTestId,
      }){return _c('ContentSelect',_vm._g({key:type,attrs:{"default-option":defaultOption,"label":label,"options":options,"type":type,"initial-value":initialValue,"data-test-id":dataTestId}},_vm.$listeners))}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }