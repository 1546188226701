export default function extractSearchValue(event) {
  let value = event && event.target ? event.target.value : undefined;

  if (!value && event) {
    try {
      value =
        event.target.parentElement.parentElement.parentElement.children[0]
          .children[0].value;

      if (!value) {
        value =
          event.target.parentElement.parentElement.parentElement.children[0]
            .children[1].value;
      }
    } catch (error) {
      // DO NOTHING
    }
  }

  return value;
}
