<template>
  <div class="latest-events text-center width-100-percent">
    <v-row justify="center">
      <v-col
        v-for="event in events"
        :key="event.id"
        class="d-inline-block pa-2 min-width-256"
        cols="12"
        sm="6"
        md="4"
      >
        <EventSummary :item="event" :data-test-id="dataTestIdEventSummary" />
      </v-col>
    </v-row>
    <div class="text-center mt-4">
      <v-btn
        outlined
        rounded
        :data-test-id="dataTestIdViewAllEvent"
        :color="viewAllButtonColor"
        :to="`/engage/${partner}/events-dashboard`"
      >
        View All
        <span class="d-sr-only">&nbsp;Latest Events</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import EventSummary from "~/components/events/EventSummary";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "LatestEvents",
  components: {
    EventSummary,
  },
  props: {
    events: { type: Array, default: () => [] },
    viewAllButtonColor: { type: String, default: "primary" },
    dataTestIdEventSummary: {
      type: [String, null],
      default: null,
    },
    dataTestIdViewAllEvent: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
  },
};
</script>
