<template>
  <v-div :pa-3="displayIsMobile || displayIsTablet" class="SummarizeGroups">
    <v-div :[headerUnderline]="true" pb-2>
      <h2 v-if="displayIsMobile" class="title font-weight-bold">
        <button
          :aria-expanded="`${showDetails}`"
          @click.prevent="onToggleDisplay"
        >
          Browse Categories
          <v-icon color="secondary" class="mt-1">{{
            showDetails ? mdiChevronUp : mdiChevronDown
          }}</v-icon>
        </button>
      </h2>
      <h2 v-else class="title font-weight-bold">Browse Categories</h2>
    </v-div>
    <ContainerGrid>
      <LayoutItems
        v-if="showDetails"
        :items="groups"
        component-name="CategorySummary"
        cols="12"
        sm="6"
        md="4"
        lg="4"
        data-test-id="CategoryCard"
      />
    </ContainerGrid>
  </v-div>
</template>

<script>
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import { mapState } from "vuex";
import ContainerGrid from "~/components/layout/ContainerGrid";
import LayoutItems from "~/components/layout/LayoutItems";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "SummarizeGroups",
  components: {
    ContainerGrid,
    LayoutItems,
  },
  props: {
    groups: { type: Array, required: true },
  },
  data() {
    return {
      showDetails: true,
      // Icons
      mdiChevronDown,
      mdiChevronUp,
    };
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    headerUnderlineSecondary() {
      return this.layout.headerUnderlineSecondaryColor;
    },
    componentName() {
      const firstItem = this.groups.length > 0 ? this.groups[0] : {};

      const type = firstItem.__typename;

      return type === "Subject" ? "SubjectSummary" : "CategorySummary";
    },
    headerUnderline() {
      return this.layout.headerUnderlineSecondaryColor;
    },
  },
  methods: {
    onToggleDisplay() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style>
.SummarizeGroups h2 button {
  width: 100%;
  text-align: left;
}

.SummarizeGroups h2 button i {
  float: right;
}
</style>
