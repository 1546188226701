<template>
  <div class="PromotedPartners text-center py-10">
    <h2 class="pb-4">Partner with us</h2>
    <div
      v-for="featuredPartner in featuredPartners"
      :key="featuredPartner.id"
      class="FeaturedPartner d-inline-block elevation-3 pa-5 my-2 mx-2"
      :style="{ backgroundColor: theme.colors.inverted }"
    >
      <a data-test-id="PartnerCard" :href="featuredPartner.url">
        <img
          v-if="displayIsMobile"
          :src="featuredPartner.logo"
          :alt="partnerLogoAltText(featuredPartner)"
          width="180px"
          height="auto"
        />
        <img
          v-else
          :src="featuredPartner.logo"
          :alt="partnerLogoAltText(featuredPartner)"
          width="auto"
          height="88px"
        />
      </a>
    </div>
    <v-btn
      class="MoreAboutPartneringButton mt-6"
      outlined
      rounded
      color="primary"
      data-test-id="FindOutMoreAboutPartneringButton"
      :style="{ backgroundColor: theme.colors.inverted }"
      :to="`/engage/${partner}/about-information?show=about-partner-with-us`"
    >
      Find out more about partnering
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsMobile,
  displayIsTablet,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "PromotedPartners",
  computed: {
    ...mapState({
      baseUrl: (state) => state.theme.baseUrl,
      theme: (state) => state.theme,
    }),
    partner() {
      return this.$config.partner;
    },
    featuredPartners() {
      return Object.values(this.theme.partners).filter(
        (item) => item.isFeaturedPartner
      );
    },

    displayIsMobile,
    displayIsTablet,
  },
  methods: {
    partnerLogoAltText(featuredPartner) {
      return `${
        featuredPartner.altTextName
          ? featuredPartner.altTextName
          : featuredPartner.name
      } Home`;
    },
  },
};
</script>

<style scoped>
.PromotedPartners p {
  max-width: 470px;
  margin: 0 auto;
}

.PromotedPartners .v-btn {
  margin: 0 auto;
  width: fit-content;
}

.FeaturedPartner {
  position: relative;
  height: 125px;
  width: 308px;
  max-width: 90%;
}

.MoreAboutPartneringButton {
  width: 308px !important;
}

a {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

h2 {
  font-size: 24px;
}

img {
  width: 100%;
  max-width: 239px;
  object-fit: scale-down;
}
</style>
