<template>
  <ContainerGrid class="ContentSelectWrapper" :no-padding="noPadding">
    <v-row class="px-3 px-md-0">
      <ContentSelect
        v-for="{
          defaultOption,
          label,
          options,
          type,
          initialValue,
          dataTestId,
        } in dataSelect"
        :key="type"
        :default-option="defaultOption"
        :label="label"
        :options="options"
        :type="type"
        :initial-value="initialValue"
        :data-test-id="dataTestId"
        v-on="$listeners"
      />
    </v-row>
  </ContainerGrid>
</template>

<script>
import ContentSelect from "~/components/public-dashboard/ContentSelect.vue";
import ContainerGrid from "~/components/layout/ContainerGrid";

export default {
  name: "ContentSelectWrapper",
  components: {
    ContentSelect,
    ContainerGrid,
  },
  props: {
    dataSelect: { type: Array, required: true },
    noPadding: { type: Boolean, default: false },
  },
};
</script>
