<template>
  <div class="marketing-search">
    <div class="width-percent-100 text-center">
      <div class="white--text">
        <SearchInput
          partner="coe"
          :label="displayLabel"
          :inverted="inverted"
          full-width
          :hide-details="true"
          data-test-search-bar-id="SearchBarText"
          data-test-search-icon-id="SearchButton"
        />
      </div>
      <div class="marketing-search__submit-container pt-5 pt-sm-10 text-center">
        <v-btn
          class="gotoSubmit marketing-search__submit-button"
          rounded
          color="button-headline"
          data-test="SubmitContentButton"
          @click="submitContent"
        >
          Submit Content
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SearchInput from "~/components/search/SearchInput.vue";
import isUnauthorized from "~/plugins/utilities/isUnauthorized";

export default {
  name: "MarketingSearch",
  components: {
    SearchInput,
  },
  props: {
    chooseSubject: { type: Boolean, default: false },
  },
  data() {
    return {
      displayLabel: "Search Cambridge Open Engage",
      inverted: true,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
    }),
  },
  methods: {
    onSearch() {
      this.$router.push({
        path: `/engage/coe/search-dashboard`,
      });
    },
    submitContent({ currentTarget }) {
      const redirectTo = this.chooseSubject
        ? `/engage/coe/submit-process/select-subject`
        : `/engage/coe/submit-process/step-one`;

      if (isUnauthorized(this.$store.state.user, "USER")) {
        this.$root.$emit(
          "show-login-options-redirect",
          redirectTo,
          currentTarget
        );
      } else {
        this.$router.push({
          path: redirectTo,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.marketing-search {
  &__submit-button {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .marketing-search {
    &__submit-button {
      width: 330px;
    }
  }
}
</style>
