<template>
  <v-col cols="12" sm="6" md="6" lg="4" class="content-select pt-0 d-relative">
    <label class="inverted--text text-capitalize" :for="comboboxId">{{
      label
    }}</label>
    <ComboboxBase
      :id="comboboxId"
      :options="[...options, defaultOption] | sortedCaseInsensitive('name')"
      :searchable="false"
      :name="label"
      :components="components"
      :value="model"
      :default-style-disabled="true"
      :default-option="defaultOption"
      :data-test-id="dataTestId"
      class="content-select__combobox mt-2"
      @updateInput="updateInput"
      @clearEntry="clearEntry"
    >
      <template #selected-option="{ data }">
        {{ getOptionLabel(data) }}
      </template>
    </ComboboxBase>
  </v-col>
</template>

<script>
import ComboboxBase from "~/components/combobox/ComboboxBase.vue";
import OpenIndicatorArrowIcon from "~/components/combobox/icons/OpenIndicatorArrowIcon.vue";

export default {
  name: "ContentSelect",
  components: {
    ComboboxBase,
  },
  props: {
    defaultOption: { type: Object, required: true },
    label: { type: String, default: "" },
    options: { type: Array, default: () => [] },
    type: { type: String, required: true },
    initialValue: { type: String, default: null },
    dataTestId: {
      type: [String, null],
      default: null,
    },
  },
  emits: ["content-select-change"],
  data() {
    return {
      model: this.defaultOption,
      components: { OpenIndicator: OpenIndicatorArrowIcon },
      comboboxId: null,
    };
  },
  watch: {
    initialValue(newInitialValue) {
      if (newInitialValue) {
        this.model = this.options.find(
          (option) => option.id === newInitialValue
        );
      }
    },
  },
  mounted() {
    this.comboboxId = `ContentSelectInput${this._uid}`;
  },
  methods: {
    getOptionLabel(option) {
      const AFTER_25_CHARACTERS_AND_THE_WORD_BOUNDARY = /^(.{25}\S*).*/;
      const MAX_LENGTH_OF_SELECTED_VALUE = 25;

      const { name } = option;

      if (name.length > MAX_LENGTH_OF_SELECTED_VALUE) {
        return name.replace(AFTER_25_CHARACTERS_AND_THE_WORD_BOUNDARY, "$1...");
      }

      return name;
    },
    updateInput(value) {
      this.model = value;
      this.$emit(
        "content-select-change",
        { id: value?.id, name: value.name },
        this.type
      );
    },
    clearEntry() {
      this.model = this.defaultOption;
      this.$emit(
        "content-select-change",
        { id: null, name: this.defaultOption.name },
        this.type
      );
    },
  },
};
</script>

<style scoped lang="scss">
.content-select {
  --vs-line-height: 1.8;

  --vs-dropdown-option--active-bg: var(--v-hardened-base);
  --vs-dropdown-option--active-color: var(--v-inverted-base);
  --vs-selected-color: var(--v-inverted-base);
  --vs-border-color: var(--v-inverted-base);

  --vs-actions-padding: 6px 12px 0 6px;
  --vs-dropdown-option-padding: 12px 16px;

  --vs-search-input-bg: none;
}

.content-select__combobox::v-deep {
  .vs__dropdown-menu {
    padding: 0 !important;
  }

  input {
    position: absolute;
    width: 100%;
  }

  .vs__dropdown-toggle {
    height: 40px;
  }

  &:not(.vs--disabled) .vs__dropdown-toggle {
    background: var(--v-accent-base);
  }

  .vs__actions {
    color: var(--v-inverted-base);
  }

  .vs__dropdown-option {
    font-size: 0.9rem;
    white-space: normal;
  }

  .vs__search {
    color: var(--v-inverted-base);
    caret-color: transparent;
  }

  .vs__search::placeholder {
    color: var(--v-inverted-base) !important;
  }
}
</style>
