<template>
  <div class="partner-sponsors">
    <h2 class="text-center">Is supported by:</h2>
    <div
      v-for="(sponsorsLine, idx) in sponsors"
      :key="idx"
      class="d-flex flex-wrap justify-center align-end"
    >
      <template v-for="sponsor in sponsorsLine">
        <v-divider
          v-if="sponsor.type === 'divider'"
          :key="sponsor.id"
          vertical
          class="partner-sponsors__divider"
        ></v-divider>
        <v-div
          v-else
          :key="sponsor.id"
          text-center
          pa-2
          :mx-2="!displayIsMobile"
          :my-2="displayIsMobile"
          :style="{ width: !displayIsMobile ? '255px' : '196px' }"
          :width-255="!displayIsMobile"
        >
          <img
            v-if="displayIsMobile"
            :src="sponsor.logo"
            :alt="`${sponsor.name} logo`"
            width="180px"
            height="auto"
          />
          <img
            v-else
            :src="sponsor.logo"
            :alt="`${sponsor.name} logo`"
            width="auto"
            height="88px"
          />
        </v-div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "MarketingMessages",
  components: {},
  props: {},
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      marketing: (state) => state.theme.marketing,
      sponsors: (state) => state.theme.sponsorsList,
    }),
  },
};
</script>

<style lang="scss" scoped>
img {
  object-fit: contain;
  max-width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img {
    height: auto !important;
  }
}

.partner-sponsors__divider {
  @media screen and (max-width: 431px) {
    display: none;
  }
}
</style>
