<template>
  <v-div class="KeyMetrics">
    <HorizontalKeyMetrics
      v-if="displayIsDesktop"
      :metrics="metrics"
      :promotions="promotions"
    />
    <VerticalKeyMetrics v-else :metrics="metrics" :promotions="promotions" />
  </v-div>
</template>

<script>
import { mapState } from "vuex";
import HorizontalKeyMetrics from "~/components/metrics/components/HorizontalKeyMetrics.vue";
import VerticalKeyMetrics from "~/components/metrics/components/VerticalKeyMetrics.vue";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "KeyMetrics",
  components: {
    HorizontalKeyMetrics,
    VerticalKeyMetrics,
  },
  props: {
    metrics: { type: Array, required: true },
  },

  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    ...mapState({
      promotions: (state) => state.theme.promotions,
    }),
    partner() {
      return this.$config.partner;
    },
  },
};
</script>
