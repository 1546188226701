<template>
  <ContainerPage class="PublicDashboardPlatform">
    <MarketingBanner />

    <LayoutGutters :class="`selectedArticlesContainer-${displayIs}`">
      <v-div
        :pl-5="displayIsMobile"
        :pr-5="displayIsMobile"
        :pl-3="displayIsTablet"
        :pr-3="displayIsTablet"
      >
        <MarketingMessages />
      </v-div>
      <v-div relative>
        <h2 class="headline inverted--text font-weight-bold py-5 text-center">
          Discover our latest content
        </h2>
        <ContentSelectWrapper
          :data-select="dataSelectOptions"
          :no-padding="true"
          @content-select-change="onChangeContentSelect"
        />
        <span class="d-sr-only" role="status" aria-atomic="true">{{
          numberOfResultsStatus
        }}</span>
        <SelectedArticles
          v-if="latestItems.length"
          :articles="latestItemsLimitByScreenSize"
          :show-content-type="showContentTypeHome"
          :no-padding="true"
          data-test-id="PreprintCard"
        />
        <p
          v-else
          aria-hidden="true"
          :class="displayIsDesktop && 'my-5'"
          class="pa-7 text-center"
          :style="{ color: theme.colors.bright }"
        >
          No matching results found
        </p>
        <v-div class="mx-5 py-1" text-center>
          <v-btn
            v-if="latestItems.length"
            class="toLatestListing"
            outlined
            rounded
            color="primary"
            data-test-id="ViewAllContentButton"
            :style="
              (displayIsMobile && 'width: 100%',
              { backgroundColor: theme.colors.inverted })
            "
            :to="browseAllContentUrl"
          >
            Explore more content
          </v-btn>
        </v-div>
      </v-div>
    </LayoutGutters>
    <div :style="{ backgroundColor: theme.colors.bright }">
      <PromotedPartners />

      <LayoutGutters>
        <h2
          class="publicDashboardHeading font-weight-bold pt-6 mb-5 text-center"
        >
          Key Metrics
        </h2>
        <v-row justify="center" class="text-center">
          <dl>
            <HeadlineMetric
              v-for="(metric, index) in metrics"
              :key="index"
              :title="metric.description"
              :value="metric.value"
              :has-more-data="index < metricsCount - 1"
              :inverted="false"
              :is-dl="true"
            />
          </dl>
        </v-row>
      </LayoutGutters>

      <LayoutCenter class="eventHeadingContainer pt-12">
        <h2
          v-if="publicDashboardShowEvents"
          class="publicDashboardHeading font-weight-bold mb-4 mx-16"
        >
          View and submit event content
        </h2>
      </LayoutCenter>

      <LayoutBackground :image="theme.images.publicDashboard.bannerFooter">
        <LayoutGutters no-padding>
          <LatestEvents
            v-if="publicDashboardShowEvents"
            :events="latestEvents"
            view-all-button-color="white"
            data-test-id-event-summary="EventCard"
            data-test-id-view-all-event="ViewAllEventsButton"
          />
          <v-div v-else height-256 />
          <Benefits />
          <v-div height-80 />
        </LayoutGutters>
      </LayoutBackground>
    </div>
  </ContainerPage>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";
import mapFlags from "~/plugins/utilities/mapFlags";

import ContainerPage from "~/components/layout/ContainerPage";
import LayoutCenter from "~/components/layout/LayoutCenter";
import LayoutGutters from "~/components/layout/LayoutGutters";
import LayoutBackground from "~/components/layout/LayoutBackground";

import MarketingBanner from "~/components/public-dashboard/MarketingBanner.vue";
import MarketingMessages from "~/components/page/components/MarketingMessages.vue";
import SelectedArticles from "~/components/articles/SelectedArticles.vue";
import HeadlineMetric from "~/components/public-dashboard/HeadlineMetric.vue";

import LatestEvents from "~/components/public-dashboard/LatestEvents.vue";
import ContentSelectWrapper from "~/components/public-dashboard/ContentSelectWrapper.vue";
import PromotedPartners from "~/components/public-dashboard/PromotedPartners.vue";
import Benefits from "~/components/public-dashboard/Benefits.vue";

const MOBILE_VIEW_LIMIT = 4;

const loadSelectedArticles = async ({ dispatch }, params) => {
  await dispatch("loadPlatformDashboardSelectedArticles", params);
};
const getSelectId = (selectId) => (selectId ? [selectId] : []);

export default {
  name: "PublicDashboardPlatform",
  components: {
    ContainerPage,
    LayoutGutters,
    LayoutCenter,
    LayoutBackground,
    MarketingBanner,
    SelectedArticles,
    HeadlineMetric,
    LatestEvents,
    ContentSelectWrapper,
    PromotedPartners,
    Benefits,
    MarketingMessages,
  },
  props: {
    displayIs: { type: String, required: true },
  },
  data() {
    return {
      contentFilters: { contentTypeId: null, subjectId: null, partnerId: null },
      contentFiltersStorage: "public-dashboard-content-filters",
      selectedValueStatus: "",
    };
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      theme: (state) => state.theme,
      marketing: (state) => state.theme.marketing,
      layout: (state) => state.theme.layout,
      categorySummaries: (state) => state.currentOrigin.categorySummaries,
      latestEvents: (state) => state.currentOrigin.latestEvents,
      metrics: (state) => state.currentOrigin.metrics,
      latestItems: (state) => state.currentOrigin.latestItems,
      contentTypes: (state) => state.referenceData.contentTypes,
      subjectTypes: (state) => state.referenceData.subjectTypes,
      partners: (state) => state.partners,
      isLoading: (state) => state.isLoading,
    }),
    ...mapFlags(["showContentTypeHome", "publicDashboardShowEvents"]),
    metricsCount() {
      return this.metrics ? this.metrics.length : 0;
    },
    showMarketingMessage() {
      return this.marketing && this.marketing.show === true;
    },
    browseAllContentUrl() {
      const { contentTypeId, subjectId, partnerId } = this.contentFilters;
      const baseUrl = `/engage/${this.partner}/search-dashboard`;

      const contentTypeIdQuery = contentTypeId && `contents=${contentTypeId}`;
      const subjectIdQuery = subjectId && `subjects=${subjectId}`;
      const partnerIdQuery = partnerId && `partners=${partnerId}`;

      const query = [contentTypeIdQuery, subjectIdQuery, partnerIdQuery]
        .filter((item) => item)
        .join("&");

      return `${baseUrl}?${query}`;
    },
    latestItemsLimitByScreenSize() {
      const limitedItems = [...this.latestItems].slice(0, MOBILE_VIEW_LIMIT);
      return this.displayIsMobile ? limitedItems : this.latestItems;
    },
    dataSelectOptions() {
      const { contentTypeId, subjectId, partnerId } = this.contentFilters;
      return [
        {
          defaultOption: { name: "All Content Types" },
          label: "Select content type",
          options: this.contentTypes,
          type: "contentTypeId",
          initialValue: contentTypeId,
          dataTestId: "ContentTypeDropdown",
        },
        {
          defaultOption: { name: "All Subjects" },
          label: "Select subject",
          options: this.subjectTypes,
          type: "subjectId",
          initialValue: subjectId,
          dataTestId: "SubjectDropdown",
        },
        {
          defaultOption: { name: "All Partners" },
          label: "Select partner",
          options: this.partners,
          type: "partnerId",
          initialValue: partnerId,
          dataTestId: "PartnerDropdown",
        },
      ];
    },
    numberOfResultsStatus() {
      if (this.isLoading) {
        return null;
      }
      return this.selectedValueStatus && this.latestItems.length
        ? `${this.selectedValueStatus}: ${this.latestItems.length} results below`
        : this.selectedValueStatus
        ? `${this.selectedValueStatus}: no matching results found`
        : "";
    },
  },
  async mounted() {
    const filterStorage = this.$sessionStorage.get(this.contentFiltersStorage);
    const storedFilters = filterStorage || null;

    if (storedFilters) this.contentFilters = storedFilters;
    await this.getSelectedContent();
  },
  methods: {
    async onChangeContentSelect(value, type) {
      this.contentFilters[type] = value.id || null;
      this.$sessionStorage.set(this.contentFiltersStorage, this.contentFilters);
      this.selectedValueStatus = `${value.name} has been selected`;

      await this.getSelectedContent();
    },
    async getSelectedContent() {
      const { contentTypeId, subjectId, partnerId } = this.contentFilters;
      const params = {
        contentTypeKeys: getSelectId(contentTypeId),
        subjectKeys: getSelectId(subjectId),
        partnerKeys: getSelectId(partnerId),
      };

      await loadSelectedArticles(this.$store, params);
    },
  },
};
</script>

<style scoped>
.PublicDashboardPlatform {
  position: relative;
}

.selectedArticlesContainer-DESKTOP {
  margin-top: -330px !important;
}

.selectedArticlesContainer-TABLET {
  margin-top: -270px !important;
}

.selectedArticlesContainer-MOBILE {
  margin-top: -370px !important;
}

.PublicDashboardPlatform .curved-base {
  margin-top: -256px;
  height: 256px;
  top: -128px;
  border-bottom-left-radius: 64%;
  border-bottom-right-radius: 64%;
  background-color: white;
  width: 128%;
  margin-left: -14%;
  z-index: 1;
}
.PublicDashboardPlatform .toLatestListing h3 {
  font-size: 0.875rem;
  font-weight: normal;
}

.eventHeadingContainer {
  margin-top: 80px;
}

.publicDashboardHeading {
  font-size: 24px;
}

dl {
  display: contents;
}
</style>
