<template>
  <v-alert
    :value="showAlert"
    :type="type"
    role="region"
    :aria-label="ariaLabel"
    :icon="icon"
  >
    <h2 v-if="title">{{ title }}</h2>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="sanitize(message)" />
  </v-alert>
</template>

<script>
import {
  displayIsDesktop,
  displayIsMobile,
  displayIsTablet,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "AlertMessageContainer",
  props: {
    showAlert: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "warning",
    },
    ariaLabel: {
      type: String,
      default: "Alert message",
    },
    icon: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
  },
  methods: {
    sanitize(text) {
      return this.$sanitize(text, {
        allowedTags: ["a", "b", "i"],
        allowedAttributes: { a: ["href", "target"] },
      });
    },
  },
};
</script>
