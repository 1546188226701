<template>
  <div class="position-relative" :style="comboboxContentStyle">
    <div class="d-flex align-center">
      <vue-select
        ref="comboboxBase"
        v-model="selectedValue"
        :class="{
          'combobox-base mb-2': !defaultStyleDisabled,
          'width-percent-100': true,
        }"
        :input-id="id"
        :clearable="clearable"
        :components="components"
        :label="label"
        :options="options"
        :placeholder="placeholder"
        :searchable="searchable"
        :map-keydown="handlers"
        :clear-search-on-select="true"
        :clear-search-on-blur="() => {}"
        :disabled="disabled"
        :data-test-id="dataTestId"
        @clearEntry="clearEntry"
        @option:selected="updateInput"
        @search="updateSearch"
        @search:focus="resetPointer"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          <div class="combobox-base__no-options px-4">{{ noOptionsLabel }}</div>
        </template>
        <template #selected-option="option">
          <slot name="selected-option" :data="option" />
        </template>
        <template #option="option">
          <slot name="option" :data="option" />
        </template>
      </vue-select>
      <ClearFieldButton
        v-if="showClearButton"
        ref="clearButton"
        :disabled="!selectedValue"
        :field-name="name"
        :announced-field-cleared="announcedFieldCleared"
        @clear="clearEntry"
      />
    </div>
    <div v-if="!hideMessages" class="combobox-base__messages px-3">
      <span role="alert" class="error--text bottom-0 font-weight-bold">{{
        error
      }}</span>
    </div>
    <div :id="comboboxBaseLabelId" class="d-sr-only">
      {{ ariaText || name }}
    </div>
    <div class="d-sr-only" role="status" aria-atomic="true">
      {{ selectedValueStatus }}
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import OpenIndicatorIcon from "~/components/combobox/icons/OpenIndicatorIcon.vue";
import ClearFieldButton from "~/components/page/components/buttons/ClearFieldButton.vue";

export default {
  name: "ComboboxBase",
  components: {
    "vue-select": vSelect,
    ClearFieldButton,
  },
  props: {
    clearable: { type: Boolean, default: false },
    components: {
      type: Object,
      default: () => ({ OpenIndicator: OpenIndicatorIcon }),
    },
    defaultOption: { type: Object, default: () => undefined },
    error: { type: String, default: "" },
    label: { type: String, default: "name" },
    ariaText: { type: String, default: "" },
    name: { type: String, required: true },
    noOptionsLabel: { type: String, default: "No results matching." },
    options: { type: Array, required: true },
    placeholder: { type: String, default: "" },
    required: { type: Boolean, default: true },
    searchable: { type: Boolean, default: false },
    id: { type: String, default: "" },
    value: { type: [Object, String], default: null },
    hideMessages: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    defaultStyleDisabled: { type: Boolean, default: false },
    showClearButton: { type: Boolean, default: false },
    disableAutoFocus: { type: Boolean, default: false },
    announcedFieldCleared: { type: Boolean, default: true },
    dataTestId: {
      type: [String, null],
      default: null,
    },
  },
  data(vm) {
    return {
      selectedValue: vm.value,
      comboboxBaseLabelId: null,
    };
  },
  computed: {
    selectedValueStatus() {
      return this.selectedValue ? this.selectedValue[this.label] : "";
    },
    isCOE() {
      return this.$config.partner.toLowerCase() === "coe";
    },
    comboboxContentStyle() {
      return {
        "--v-border-radius": this.isCOE ? "3px" : "36px",
        "--v-outline-width": this.isCOE ? "1.4px" : "2px",
        "--v-border-width": this.error ? "2px" : "1px",
      };
    },
  },
  watch: {
    value(newVal) {
      this.selectedValue = newVal;
    },
  },
  mounted() {
    this.comboboxBaseLabelId = `ComboboxBaseLabel${this._uid}`;

    const comboboxBase =
      this.$refs.comboboxBase.$el.querySelector('[role="combobox"]');
    comboboxBase.setAttribute("aria-label", this.ariaText || this.name);
    comboboxBase.setAttribute("aria-haspopup", "listbox");

    const input = this.$refs.comboboxBase.$el.querySelector("input");
    input.setAttribute("aria-labelledby", this.comboboxBaseLabelId);
    input.removeAttribute("readonly");

    comboboxBase.appendChild(input);
  },
  methods: {
    resetPointer() {
      this.$nextTick(() => (this.$refs.comboboxBase.typeAheadPointer = -1));
    },
    clearEntry() {
      if (this.defaultOption) {
        this.selectedValue = this.defaultOption;
      } else {
        this.selectedValue = null;
      }

      this.$emit("clearEntry");
      if (!this.disableAutoFocus && this.showClearButton) {
        this.focusCombobox();
      }
    },
    handlers: (map, vm) => ({
      ...map,
      46: () => {
        vm.$emit("clearEntry");
      },
    }),
    updateSearch(value) {
      this.$emit("search", value);
    },
    updateInput(value) {
      this.$emit("updateInput", value);
      if (!this.disableAutoFocus && this.showClearButton) {
        this.focusClearButton();
      }
    },
    focusCombobox() {
      this.$nextTick(() =>
        this.$refs.comboboxBase.$el.querySelector("input").focus()
      );
    },
    focusClearButton() {
      this.$nextTick(() => this.$refs.clearButton.focus());
    },
  },
};
</script>

<style scoped lang="scss">
.combobox-base {
  --vs-line-height: 2.8;

  --vs-dropdown-option--active-bg: var(--v-hardened-base);
  --vs-dropdown-option--active-color: var(--v-inverted-base);
  --vs-selected-color: inherit;
  --vs-border-color: var(--v-action-base);
  --vs-controls-color: var(--v-action-base);

  --vs-actions-padding: 6px 12px 0 6px;
  --vs-dropdown-option-padding: 12px 16px;

  --vs-dropdown-z-index: 1006;

  --vs-search-input-bg: none;

  border-radius: var(--v-border-radius);

  &__no-options {
    text-align: left;
  }

  &__messages {
    min-height: 14px;
    margin-bottom: 8px;
  }
}

// Following classes from vue-select library
.combobox-base::v-deep {
  .vs__dropdown-menu {
    padding: 8px 0 !important;
    top: 60px;
    box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
    border-radius: 0 0 4px 4px;
    border: none;
  }

  &:not(.vs--disabled) .vs__dropdown-toggle {
    background: var(--v-inverted-base);
  }

  input {
    position: absolute;
    width: 100%;
  }

  .vs__dropdown-toggle {
    padding-left: 15px;
    border-radius: var(--v-border-radius);
    border: var(--v-border-width) solid;

    overflow: hidden;
  }

  .vs__dropdown-option {
    white-space: normal;
  }

  .vs__selected-options {
    padding: 0 !important;
    flex-wrap: nowrap;
    min-height: 50px;
  }

  .vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
    opacity: 1 !important;
  }

  .vs--open {
    outline: var(--v-dim-base) var(--v-outline-width) solid;
  }

  .vs__selected {
    padding-left: 0;
    margin-left: 0;
    line-height: 1.75rem !important;
    z-index: 2;
  }

  .vs__search {
    padding: 0;
  }
}
</style>
